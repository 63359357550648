import Image from 'next/image'
import React, { useState, useEffect, useRef } from 'react'
import Mustache from 'mustache'
import { getAdvisorDisplayName, isDesktop } from '@/utils/Helpers'
import {
  AdvisorBySlugProps,
  checkifIsProfile,
} from '@/components/advisors/Types'
import { MetaContentHead } from '@/components/common/MetaContentHead'
import { Layout } from '@/components/Layout'
import { fetchMenuCategories } from '@/utils/Contentful'
import { fetchContent } from '@/utils/FetchContent'
import { AdvisorExpertiseList } from '@/components/advisors/AdvisorExpertiseList'
import {
  AdvisorContactCTA,
  AdvisorContactDisabledCTA,
  AdvisorContactCTAMobile,
  AdvisorContactProfile,
} from '@/components/advisors/AdvisorCTAs'
import {
  compatibleCloudinaryImageLoader,
  imageLoader,
} from '@/utils/ImageLoaders'
import { RichTextContent } from '@/components/design/RichTextContent'
import {
  getItineraryCardFragment,
  getTripReportCardFragment,
} from '@/components/itineraries/ItineraryCard'
import { Modal } from '@/components/common/Modal'
import { AdvisorSignUpSuccessModal } from '@/components/forms/AdvisorSignUpSuccessModal'
import { AdvisorContactForm } from '@/components/forms/AdvisorContactForm'
import { useRouter } from 'next/router'
import { getAdvisorCopy } from '@/components/advisors/Utils'
import { SocialMediaIconList } from '@/components/advisors/SocialMediaList'
import {
  Asset,
  ComponentImage as ComponentImageType,
} from 'types/generated/contentful-types'
import SpacerComponent from '@/components/pageBlocks/SpacerComponent'
import { ContentTypeLabels } from 'types'
import ComponentImage from '@/components/pageBlocks/ComponentImage'
import useBreakpoint from 'hooks/useBreakpoints'
import { AdvisorGuides } from '@/components/advisors/AdvisorGuides'
import { AdvisorHotels } from '@/components/advisors/AdvisorHotels'
import { AdvisorMailingListForm } from '@/components/forms/AdvisorMailingListForm'
import { AdvisorTestimonials } from '@/components/advisors/AdvisorTestimonials'
import { HotelFragment } from '@/components/pageBlocks/fragments'
import { REVALIDATION_INTERVALS } from '@/utils/build/constants'
import { logToSentryWithLocalScope } from '@/utils/SentryLogger'
import ForaSwiper from '@/components/swiper/ForaSwiper'
import styles from '@/components/swiper/components/advisor-image-carousel.module.css'

const MOBILE_CTA_VISIBILITY_THRESHOLD = 300
const PRONOUN_NOT_SPECIFIED = 'prefer not to specify'

export const getBadge = (tier) => {
  switch (tier) {
    case 'Advanced':
      return (
        <Image
          loader={() =>
            imageLoader({
              src: 'https://media.foratravel.com/image/upload/v1716476585/advanced-tier_1_i3uimn.svg',
              width: 128,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1716476585/advanced-tier_1_i3uimn.svg"
          width={128}
          height={32}
          alt="Icon"
        />
      )
    case 'Certified':
      return (
        <Image
          loader={() =>
            imageLoader({
              src: 'https://media.foratravel.com/image/upload/v1716476585/certified-tier_1_pjxqug.svg',
              width: 128,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1716476585/certified-tier_1_pjxqug.svg"
          width={181}
          height={48}
          alt="Icon"
        />
      )
    case 'Pro':
      return (
        <Image
          loader={() =>
            imageLoader({
              src: 'https://media.foratravel.com/image/upload/v1716476585/pro-tier_1_xb8hpf.svg',
              width: 70,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1716476585/pro-tier_1_xb8hpf.svg"
          width={70}
          height={32}
          alt="Icon"
        />
      )
    case 'Fora X':
      return (
        <Image
          loader={() =>
            imageLoader({
              src: 'https://media.foratravel.com/image/upload/v1718123668/fora-x_ox2s5h.svg',
              width: 183,
              quality: 90,
            })
          }
          src="https://media.foratravel.com/image/upload/v1718123668/fora-x_ox2s5h.svg"
          width={183}
          height={42}
          alt="Icon"
        />
      )
  }
}

const AdvisorPage = ({
  advisor,
  linkedFrom,
  globalConfigs,
  isPreview,
  menuLinks,
}: AdvisorBySlugProps): JSX.Element => {
  const advisorCopy = getAdvisorCopy(
    advisor.advisorVariant,
    advisor.title,
    advisor.firstName
  )
  const [showingSubmitFormModal, toggleShowingSubmitFormModal] =
    useState<boolean>(false)
  const [bottomCardVisible, toggleBottomCardVisible] = useState<boolean>(false)

  // The sticky panel
  const stickyRef = useRef<HTMLDivElement>(null)
  // Part of the panel does not appear sticky
  const staticRef = useRef<HTMLDivElement>(null)
  // An element that is revealed while sticking
  const revealRef = useRef<HTMLDivElement>(null)
  // Detect when the panel is sticking
  const sentinelRef = useRef<HTMLDivElement>(null)
  const isPageLoaded = useRef<boolean>(false)
  const initScrollPos = useRef<number>() // to use actual value inside scroll listener
  initScrollPos.current = initScrollPos.current || 0
  const cardVisibleRef = useRef<boolean>() // to use actual value inside scroll listener
  cardVisibleRef.current = false
  const router = useRouter()
  const breakpoint = useBreakpoint()
  const isDesktopContext = isDesktop(breakpoint)

  const metaDescription = Mustache.render(
    globalConfigs.advisorsMetaDescription,
    {
      advisorName: advisor.title,
    }
  )
  const testimonials =
    linkedFrom.entryTestimonialCollection.items
      .filter((item) => item)
      .filter((item) => item.display) || []

  useEffect(() => {
    if (checkifIsProfile(advisor.advisorVariant)) {
      router.push(`/profile/${advisor.slug}`)
    }
  }, [advisor.advisorVariant, advisor.slug, router])

  useEffect(() => {
    const checkPosition = () => {
      if (detectDirection() === 'down') {
        if (cardVisibleRef.current) {
          initScrollPos.current = window.scrollY
        } else if (
          (initScrollPos.current || 0) + MOBILE_CTA_VISIBILITY_THRESHOLD <=
          window.scrollY
        ) {
          initScrollPos.current = window.scrollY
          cardVisibleRef.current = true
          toggleBottomCardVisible(true)
        }
      } else {
        if (
          (initScrollPos.current || 0) - window.scrollY >=
          MOBILE_CTA_VISIBILITY_THRESHOLD
        ) {
          initScrollPos.current = window.scrollY
          cardVisibleRef.current = false
          toggleBottomCardVisible(false)
        }
      }
    }

    let lastScrollTop = 0,
      direction
    const detectDirection = () => {
      const st = window.pageYOffset || document.documentElement.scrollTop
      if (st > lastScrollTop) {
        direction = 'down'
      } else {
        direction = 'up'
      }
      lastScrollTop = st <= 0 ? 0 : st
      return direction
    }

    window.addEventListener('scroll', checkPosition, { passive: true })

    return function cleanupListener() {
      window.removeEventListener('scroll', checkPosition)
    }
  }, [])

  const itinerariesAndTripReports = [
    ...linkedFrom?.itinerariesCollection?.items,
    ...linkedFrom?.entryTripReportCollection?.items,
  ]
  const hasItinerariesAndTripReports = itinerariesAndTripReports.length > 0

  const hasImageComponents =
    advisor.imageCarouselCollection?.items &&
    advisor.imageCarouselCollection?.items.length > 0

  const hasHotels =
    advisor.hotelsCollection.items && advisor.hotelsCollection.items.length > 0

  //TODO: Implement logic to display advisor stories collection
  const hasStories = false

  useEffect(() => {
    // Reveal hidden element when it is sticking
    const handleStickyChange = (entry: IntersectionObserverEntry) => {
      if (!isPageLoaded.current) return (isPageLoaded.current = true)
      if (!isDesktopContext || !stickyRef.current || !revealRef.current) return
      const isSticking = !entry.isIntersecting
      if (isSticking) {
        revealRef.current.style.animation = 'fadeIn 350ms ease-in-out forwards'
        stickyRef.current.style.animation = ''
        void stickyRef.current.offsetWidth // End browser batch changes
        stickyRef.current.style.animation = 'fadeOutIn70 350ms ease-in-out'
      } else {
        revealRef.current.style.animation = 'fadeOut 350ms ease-in-out forwards'
        stickyRef.current.style.animation = ''
        void stickyRef.current.offsetWidth // End browser batch changes
        stickyRef.current.style.animation = 'fadeOutIn70 350ms ease-in-out'
      }
    }

    // Observe when the hidden element has reached the top of the viewport
    const observer = new IntersectionObserver(
      (entries) => entries.forEach(handleStickyChange),
      { rootMargin: '0px 0px 100% 0px', threshold: 0 }
    )
    const sentinelEl = sentinelRef.current
    if (sentinelEl) observer.observe(sentinelEl)
    return () => {
      if (sentinelEl) observer.unobserve(sentinelEl)
    }
  }, [sentinelRef, isDesktopContext])

  useEffect(() => {
    /* The entire panel is sticky, but only part of it appears to stick.
       Position the elements within the panel to accomplish this. */
    const handleResizePanel = () => {
      if (!staticRef.current || !revealRef.current || !stickyRef.current || !sentinelRef.current) return // prettier-ignore
      if (!isDesktopContext)
        return (staticRef.current.style.marginBottom = '0px')
      const revealHeight = revealRef.current.offsetHeight // The height of the element that will be revealed
      const sentinelPos = sentinelRef.current?.offsetTop // Where the sentinel is inside the panel
      // Position the hidden element so that it overlaps the static element
      staticRef.current.style.marginBottom = `-${revealHeight + 2}px` // Also add a couple pixels to resolve subpixel rendering issue
      // Start sticking where the sentinel is
      if (sentinelPos) stickyRef.current.style.top = `-${sentinelPos + 2}px`
    }

    // Observe when the panel resizes
    const observer = new ResizeObserver((entries) =>
      entries.forEach(handleResizePanel)
    )
    const stickyEl = stickyRef.current
    if (stickyEl) observer.observe(stickyEl)
    return () => {
      if (stickyEl) observer.unobserve(stickyEl)
    }
  }, [stickyRef, isDesktopContext])

  const sharedUrls =
    advisor.instagramUrl ||
    advisor.tikTokUrl ||
    advisor.twitterUrl ||
    advisor.facebookUrl ||
    advisor.pinterestUrl ||
    advisor.linkedinUrl ||
    advisor.youtubeUrl ||
    advisor.websiteUrl

  return (
    <Layout
      showPreviewBanner={isPreview}
      menuLinks={menuLinks}
      advisor={advisor}
      expanded={false}
      hideFooterLinks
    >
      <MetaContentHead
        metaTitle={`${advisor.title} | Fora Travel`}
        metaDescription={metaDescription}
        metaImage={advisor.image as Asset}
        cloudinaryMetaImage={advisor.cloudinaryImage}
        ogType="article"
      >
        <meta
          name="article:published_time"
          content={advisor.sys.firstPublishedAt}
        />
        <meta name="article:modified_time" content={advisor.sys.publishedAt} />
      </MetaContentHead>
      <div className="lg:flex block gap-[70px] justify-between advisor-content">
        <div className="relative m-auto w-3/4 md:w-[375px] lg:m-0">
          <div ref={stickyRef} className="z-10 lg:sticky">
            <div
              ref={staticRef}
              className="text-center bottom-full pt-14 reduced lg:bg-shell profile-block lg:p-12 lg:pb-0"
            >
              <h2 className="mb-4 uppercase fora-text-eyebrow-1 md:fora-text-eyebrow-3">
                {advisorCopy.heroEyebrow}
              </h2>
              <div className="flex justify-center w-3/4 m-auto">
                {advisor.cloudinaryImage && (
                  <Image
                    loader={() =>
                      compatibleCloudinaryImageLoader({
                        public_id:
                          advisor.cloudinaryImage?.[0]?.public_id || '',
                        width: 640,
                        height: 640,
                        quality: 90,
                        fit: 'fill',
                      })
                    }
                    className="rounded-full"
                    src={advisor.cloudinaryImage?.[0]?.secure_url}
                    data-src={advisor.cloudinaryImage?.[0]?.secure_url}
                    alt={`Advisor - ${advisor.title}`}
                    width={640}
                    height={640}
                    quality={90}
                    objectFit="cover"
                  />
                )}
              </div>
              <SpacerComponent paddingBelow="XXS" paddingBelowMobile="XXS" />
              <h1 className="fora-text-h3 md:fora-text-h5">{advisor.title}</h1>
              {advisor.pronouns &&
                advisor.pronouns.toLowerCase() !== PRONOUN_NOT_SPECIFIED && (
                  <>
                    <SpacerComponent
                      paddingBelow="XXS"
                      paddingBelowMobile="XXS"
                    />
                    <p className="mb-0 fora-text-body-1 md:fora-text-body-2">
                      {advisor.pronouns}
                    </p>
                  </>
                )}
              {advisor.tier && (
                <>
                  <SpacerComponent paddingBelow="XL" paddingBelowMobile="XL" />
                  <div className="flex items-center justify-center w-3/4 gap-2 m-auto">
                    {advisor.tier !== 'Fora X' && (
                      <div className="w-[90px] relative flex items-center">
                        <Image
                          src="https://media.foratravel.com/image/upload/v1715287680/fora-website-assets/snp9bmzrjdxxdjlivbm8.svg"
                          width={90}
                          height={28}
                          alt="Fora - Logo"
                        />
                      </div>
                    )}
                    <div className="relative flex justify-start">
                      {getBadge(advisor.tier)}
                    </div>
                  </div>
                </>
              )}
              <SpacerComponent paddingBelow="XL" paddingBelowMobile="XL" />
              <h2 className="mb-2 uppercase lg:mb-[14px] fora-text-eyebrow-1 md:fora-text-eyebrow-3">
                Expertise
              </h2>
              <AdvisorExpertiseList
                {...{ expertise: advisor.expertise, compact: true }}
              />
              {advisor.basedIn && (
                <>
                  <SpacerComponent paddingBelow="XL" paddingBelowMobile="XL" />
                  <h2 className="mb-2 uppercase lg:mb-[14px] fora-text-eyebrow-1 md:fora-text-eyebrow-3">
                    Based in
                  </h2>
                  <div>
                    <RichTextContent richText={advisor.basedIn} />
                  </div>
                </>
              )}
              {advisor.languagesSpoken && (
                <>
                  <SpacerComponent paddingBelow="XL" paddingBelowMobile="XL" />
                  <h2 className="mb-2 uppercase lg:mb-[14px] fora-text-eyebrow-1 md:fora-text-eyebrow-3">
                    Languages spoken
                  </h2>
                  <div>
                    <RichTextContent richText={advisor.languagesSpoken} />
                  </div>
                </>
              )}
              {advisor.minimumBooking ? (
                <>
                  <SpacerComponent paddingBelow="XL" paddingBelowMobile="XL" />
                  <h2 className="mb-2 lg:mb-[14px] fora-text-eyebrow-1 md:fora-text-eyebrow-3">
                    Minimum booking Budget
                  </h2>
                  <div className="flex items-center justify-center">
                    <div className="global-richtext">
                      <p>${advisor.minimumBooking}/night</p>
                    </div>
                  </div>
                </>
              ) : null}
              <SpacerComponent paddingBelow="XL" paddingBelowMobile="LG" />
            </div>
            <div ref={sentinelRef} className="h-px -mt-px" aria-hidden />
            <div className="relative z-10">
              {advisor.advisorContactEnabled ? (
                <section>
                  <div
                    ref={revealRef}
                    className="hidden px-10 opacity-0 pb-7 pt-18 lg:block lg:bg-shell"
                    // Close the seam caused by the separate backgrounds
                    style={{ marginBottom: '-1px' }}
                  >
                    <AdvisorContactProfile
                      {...advisor}
                      CTABylineEyebrow={advisorCopy.CTAeyebrow}
                      center
                    />
                  </div>
                  <div className="lg:px-16 lg:pb-18 lg:bg-shell">
                    <AdvisorContactCTA
                      {...advisor}
                      CTABylineEyebrow={advisorCopy.CTAeyebrow}
                      CTABodyText=""
                      compact
                      CTAButtonText={`Contact ${advisor.firstName}`}
                      buttonOnly
                    />
                  </div>
                </section>
              ) : (
                <div ref={revealRef} className="hidden opacity-0 lg:block">
                  <AdvisorContactDisabledCTA
                    {...advisor}
                    CTABylineEyebrow={ContentTypeLabels[advisor.advisorVariant]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <SpacerComponent paddingBelowMobile="XL" />
        <section className="w-full lg:w-4/6 lg:min-w-0">
          <div>
            <h4 className="mb-4 uppercase fora-text-eyebrow-1">
              Profile details
            </h4>
          </div>
          <SpacerComponent
            hasDivider
            paddingBelow="MD"
            paddingBelowMobile="MD"
          />

          <h2 className="mb-2 md:fora-text-h4 fora-text-h3">About</h2>
          <div className="fora-text-body-1">
            <RichTextContent richText={advisor.aboutMeIAm} />
          </div>
          {sharedUrls && (
            <>
              <SpacerComponent paddingTop="MD" paddingTopMobile="MD" />
              <div>
                <h3 className="mb-3 uppercase fora-text-h9">
                  Follow {advisor.firstName}
                </h3>
                <SocialMediaIconList size={45} {...advisor} />
              </div>
            </>
          )}

          <SpacerComponent
            hasDivider
            paddingBelow="MD"
            paddingBelowMobile="MD"
            paddingTop="MD"
            paddingTopMobile="MD"
          />

          <div>
            <h2 className="mb-6 md:fora-text-h4 fora-text-h3">
              {/* {advisorCopy.profileBodyMyTravelStyle} */}
              Travel style
            </h2>
            <RichTextContent richText={advisor.myTravelStyleQuote} />
          </div>

          <SpacerComponent
            hasDivider
            paddingBelow="MD"
            paddingBelowMobile="MD"
            paddingTop="MD"
            paddingTopMobile="MD"
          />

          {hasItinerariesAndTripReports && (
            <>
              <AdvisorGuides advisor={advisor} />
              <SpacerComponent paddingBelow="XL" paddingTopMobile="MD" />
            </>
          )}

          {hasHotels && (
            <>
              <AdvisorHotels hotels={advisor.hotelsCollection.items} />
              <SpacerComponent paddingBelow="XL" paddingTopMobile="MD" />
            </>
          )}

          {hasStories ? (
            <div className="relative pb-12 border-b border-darkShell lg:pb-22 lg:mb-22">
              <h2 className="mb-8 fora-text-h5">
                {getAdvisorDisplayName(advisor.title, advisor.firstName)}&#39;s
                stories
              </h2>
            </div>
          ) : null}

          {hasImageComponents && (
            <>
              <section className="relative">
                <h2 className="mb-6 lg:mb-8 fora-text-h5">
                  {getAdvisorDisplayName(advisor.title, advisor.firstName)}
                  &#39;s travel photos
                </h2>
                <ForaSwiper
                  customPagination
                  className={styles.foraSwiper}
                  params={{
                    navigation: true,
                    pagination: false,
                    keyboard: true,
                    slidesPerView: 1,
                    spaceBetween: 10,
                    centeredSlides: false,
                    breakpoints: {
                      768: {
                        slidesPerView: 1,
                      },
                      1024: {
                        slidesPerView: 1.5,
                      },
                    },
                  }}
                >
                  {advisor.imageCarouselCollection?.items.map(
                    (componentImage: ComponentImageType, i) => (
                      <ComponentImage
                        key={`ComponentImageCard_${i}`}
                        {...componentImage}
                        width={670}
                        height={838}
                      />
                    )
                  )}
                </ForaSwiper>
              </section>
              <SpacerComponent paddingBelow="XL" paddingTopMobile="MD" />
            </>
          )}

          {testimonials.length > 0 && (
            <AdvisorTestimonials
              testimonials={testimonials}
              advisor={advisor}
            />
          )}

          {advisor.advisorContactEnabled && (
            <section className="relative mb-14 lg:mb-[76px]">
              <AdvisorContactForm
                {...advisor}
                conversionComponentName="Profile Page"
                heading={advisorCopy.CTABoxBottom}
                subheading={advisorCopy.CTABoxCopy}
                submitButtonText={advisorCopy.CTAbutton}
                advisorBylineEyebrow={advisorCopy.CTAeyebrow}
                compactVersion
                showRightPanel={false}
                onSubmit={() => {
                  toggleShowingSubmitFormModal(!showingSubmitFormModal)
                }}
              />
            </section>
          )}

          {advisor.mailChimpForm && (
            <AdvisorMailingListForm
              conversionComponentName="Profile Page"
              title={advisor.title}
              firstName={advisor.firstName}
              lastName={advisor.lastName}
              slug={advisor.slug}
              mailChimpForm={advisor.mailChimpForm}
            />
          )}
        </section>
      </div>
      <section
        className={`fixed left-0 z-10 w-full lg:hidden transition-all duration-500  
            ${bottomCardVisible ? 'bottom-0' : '-bottom-20'}`}
      >
        <AdvisorContactCTAMobile {...advisor} />
      </section>
      <Modal
        onClose={() => toggleShowingSubmitFormModal(!showingSubmitFormModal)}
        visible={showingSubmitFormModal}
      >
        <AdvisorSignUpSuccessModal
          onClose={() => toggleShowingSubmitFormModal(!showingSubmitFormModal)}
          displayName={getAdvisorDisplayName(advisor.title, advisor.firstName)}
        />
      </Modal>
      <SpacerComponent paddingTop="MD" paddingTopMobile="MD" />
    </Layout>
  )
}

export async function getStaticPaths() {
  return { paths: [], fallback: 'blocking' }
}

export const getStaticProps = async ({ params, preview = false }) => {
  try {
    const data = await fetchContent(
      `
      {
        advisorsCollection(where: { slug: "${
          params?.slug
        }" }, limit: 1, preview: ${preview}) {
          items {
            sys {
              publishedAt
              firstPublishedAt
            }
            title
            slug
            advisorVariant
            advisorContactEnabled
            firstName
            lastName
            pronouns
            emailAddress
            mailChimpForm
            image {
              url
              description
            }
            cloudinaryImage
            expertise
            tier
            basedIn {
              json
            }
            languagesSpoken {
              json
            }
            minimumBooking
            aboutMeIAm {
              json
            }
            aboutMeHomeAirport {
              json
            }
            myTravelStyleQuote {
              json
            }
            myTravelStyleRecentlyReturnedFrom {
              json
            }
            myTravelStyleDreamingOf {
              json
            }
            myTravelStyleCantLiveWithout {
              json
            }
            myTravelStyleTwoTruthsOneLie {
              json
            }
            hotelsCollection(limit:12) {
              items {
                ${HotelFragment}
              }
            }
            preferredGuidesCollection(limit:12) {
              items {
              ...on Itineraries {${getItineraryCardFragment({
                includeCategories: false,
              })}}
              ...on EntryTripReport {${getTripReportCardFragment({
                includeCategories: false,
              })}}
            }

            }
            linkedFrom {
              itinerariesCollection(limit: 99, order: sys_publishedAt_DESC) {
                items {
                  ${getItineraryCardFragment({ includeCategories: false })}
                }
              }
              entryTripReportCollection(limit: 99, order: sys_publishedAt_DESC) {
                items {
                  ${getTripReportCardFragment({ includeCategories: false })}
                }
              }
              entryTestimonialCollection(limit: 99) {
                items {
                  display
                  stars
                  submitterFirstName
                  submitterLastName
                  dateSubmitted
                  submittedTestimonial
                  testimonial {
                    json
                  }
                  destinationsSummary {
                    json
                  }
                  submitterImage {
                    url
                  }
                }
              }
            }
            instagramUrl
            tikTokUrl
            facebookUrl
            twitterUrl
            websiteUrl
            pinterestUrl
            linkedinUrl
            youtubeUrl
            imageCarouselCollection {
              items {
                altText
                imageCredit
                cloudinaryImage
                file {
                  url
                  width
                  height
                }
              }
            }
          }
        }
        templatesGlobalConfigsCollection(limit:1) {
          items {
            advisorsMetaDescription
            itinerariesMetaDescription
          }
        }
      }
    `,
      preview
    )

    // Handle service outage or unsuccessful fetch to serve cached page
    if (!data) {
      return undefined
    }

    const advisor = data?.advisorsCollection?.items?.[0]

    if (!advisor) {
      return {
        redirect: {
          destination: '/advisors',
          permanent: false,
        },
      }
    }

    const linkedFrom = data?.advisorsCollection?.items?.[0]?.linkedFrom
    const globalConfigs = data?.templatesGlobalConfigsCollection?.items?.[0]

    return {
      props: {
        advisor,
        linkedFrom,
        globalConfigs,
        isPreview: preview,
        menuLinks: await fetchMenuCategories(),
      },
      revalidate: REVALIDATION_INTERVALS.EVERY_5_MINUTES,
    }
  } catch (error) {
    console.error(error)
    logToSentryWithLocalScope(
      error,
      {
        tags: { service: 'Page Build' },
        level: 'error',
      },
      'Failed to build advisor slug page'
    )
  }
  return undefined
}

export default AdvisorPage
